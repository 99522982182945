// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import '@fortawesome/fontawesome-free/css/all'

require.context('../mypage/images', true)

import 'bxslider/dist/jquery.bxslider.min.css'
import 'mypage/css/system/system.base'
import 'mypage/css/system/system.messages.css'
import 'mypage/css/youtube.css'
import 'mypage/css/bootstrap.mina560.css'
import 'mypage/css/mypage.css'
import 'mypage/css/style.css'
import 'mypage/css/meanmenu.css'
import 'mypage/css/custom-styles.css'
import 'mypage/css/j-custom.css'
import 'mypage/css/exposed-job-search-form.css'
import 'mypage/css/sumoselect.css'
import 'mypage/css/content.css'
import 'mypage/css/update.css'
import 'mypage/css/exposed-job-search-form.css'
import 'mypage/css/sumoselect.css'
import 'mypage/css/job.css'
import 'mypage/css/closeup.css'
import 'mypage/css/home.css'
import 'mypage/css/blocked_companies.css'
import 'select2/dist/css/select2.css'
import 'mypage/css/search.css'
import 'mypage/css/job-search.css'
import 'mypage/css/modal.css'
import 'mypage/css/view_save.css'
import 'mypage/css/notifications.css'
import 'mypage/css/jcarousel-default.css'

import I18n from 'i18n-js/index.js.erb'
window.I18n = I18n

import 'jquery-ui'
import 'bxslider/dist/jquery.bxslider.min.js'

import { SETTINGS } from 'mypage/js/drupal_settings'

import 'mypage/js/jquery'
import 'mypage/js/jquery.once'
import 'mypage/js/drupal'
import 'mypage/js/jquery.form'
import 'mypage/js/jquery-extend-3.4.0'
import 'mypage/js/jquery-html-prefilter-3.5.0-backport'
import 'mypage/js/jquery.sumoselect'
import 'mypage/js/ajax'
import 'mypage/js/progress'
import 'mypage/js/skill'
import 'select2'
import 'mypage/js/yubinbango-ex'
import 'mypage/js/jp_location_entities'
import 'mypage/js/profile'
import 'mypage/js/favorite'
import 'mypage/js/modal'
import 'mypage/js/tab'
import 'mypage/js/job_search'
import 'mypage/js/custom'
import 'mypage/js/auto-submit'
import 'mypage/js/view_save'
import 'mypage/js/notify_modal'
import 'mypage/js/jquery.jcarousel'

$.extend(Drupal.settings, SETTINGS)

import 'mypage/js/form'
import 'mypage/js/collapse'
import 'mypage/js/account'
import 'mypage/js/back_to_top'
import 'mypage/js/jquery.meanmenu'
import 'mypage/js/body_scroll_lock'
import 'mypage/js/responsive_menus_mean_menu'
import 'mypage/js/maxlength'
import 'shared/career'
import 'shared/open_new_tab'
import 'mypage/js/jcarousel'
import 'mypage/js/header'

// Drupal.behaviors.collapse

Rails.start()

window.jQuery = $;
window.$ = $;

$(function() {
  $('.bxslider').bxSlider({
    auto: true,
    pager: false
  });
  if (location.pathname != '/') {
    $('#block-menu-menu-footer-menu li.leaf a[href^="' + location.pathname + '"]').addClass('active');
  }
});
